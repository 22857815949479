package com.radioshqiplive.data

object URLS {

    const val LINK_GITHUB = "https://github.com/eltonkola"
    const val LINK_PLAY_STORE = "https://play.google.com/store/apps/dev?id=6135815754208595340"
    const val LINK_FACEBOOK = "https://facebook.com/pages/radioshqip"

    const val LINK_WEB = "https://radioshqiplive.com/"

    const val DEFAULT_IMAGE =  "defaultImg.jpg"

    const val WALLPAPER_1 = "wallpaper.jpg"

}

const val isDebugBuild = false
