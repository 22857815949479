package com.radioshqiplive.data

import com.radioshqiplive.data.model.Radio
import com.radioshqiplive.data.service.RadioApi
import com.radioshqiplive.data.service.RadioApiImpl
import com.radioshqiplive.player.MediaPlayerController
import com.radioshqiplive.player.MediaPlayerListener
import ioDispatcher
import kotlinx.coroutines.CoroutineExceptionHandler
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.async
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.flow.map
import kotlinx.coroutines.flow.update
import kotlinx.coroutines.launch

sealed class UiState {
    object Loading : UiState()
    class Error(val error: String) : UiState()
    data class AppReady(
        val radios: List<Radio>,
        val currentRadio: Radio? = null,
    ) : UiState()
}

data class FilterState(
    val search: String = "",
    val favoritesOnly: Boolean = false,
    val sortBy: Sort = Sort.Default
)

sealed class Sort(val name: String, val order: Int) {
    object Default : Sort("Normal", 0)
    object AZ : Sort("AZ", 1)
    object ZA : Sort("ZA", 2)
    object RANDOM : Sort("RANDOM", 3)
}


class RadioViewModel(
    private val mediaPlayerController: MediaPlayerController,
    private val api: RadioApi = RadioApiImpl()
) {

    val uiState = MutableStateFlow<UiState>(UiState.Loading)
    val playerState = mediaPlayerController.state

    private val _filterState = MutableStateFlow(FilterState())
    val filterState = _filterState


    private val coroutineExceptionHandler = CoroutineExceptionHandler { _, exception ->
        exception.printStackTrace()
        uiState.value = UiState.Error(exception.message.toString())
    }

    private val job = SupervisorJob()
    private val viewModelScope = CoroutineScope(ioDispatcher() + coroutineExceptionHandler + job)

    private var radios = mutableListOf<Radio>()

    init {
        viewModelScope.launch {
            try {
                val kanalet = async { api.getKanalet() }.await()
                radios.addAll(kanalet)
                uiState.value = UiState.AppReady(radios = emptyList())
                filterContent()

                mediaPlayerController.setMediaList(kanalet)

            } catch (e: Exception) {
                e.printStackTrace()
                uiState.value = UiState.Error(e.message.toString())
            }
        }

        viewModelScope.launch {
            _filterState.collect {
                if (uiState.value is UiState.AppReady) {
                    filterContent()
                }
            }
        }
    }

    private fun filterContent() {
        val filter = filterState.value
        uiState.update {
            val currentState: UiState.AppReady = uiState.value as UiState.AppReady

            val filteredRadios = radios.filter {//filter favorites
                if (filter.favoritesOnly) {
                    it.favorite
                } else {
                    true
                }
            }.filter {
                if (filter.search.isNotEmpty()) { //filter search
                    it.emri.contains(filter.search)
                } else {
                    true
                }
            }
            val orderedRadios = when (filter.sortBy) {
                Sort.Default -> {
                    filteredRadios.sortedBy { it.orderz }
                }

                Sort.AZ -> {
                    filteredRadios.sortedBy { it.emri }
                }

                Sort.ZA -> {
                    filteredRadios.sortedBy { it.orderz }.reversed()
                }

                Sort.RANDOM -> {
                    filteredRadios.shuffled()
                }
            }

            currentState.copy(radios = orderedRadios)
        }
    }

    fun onSearchUpdate(query: String) {
        _filterState.update {
            it.copy(search = query)
        }
    }

    fun onFavoriteFilter(favoritesOnly: Boolean) {
        _filterState.update {
            it.copy(favoritesOnly = favoritesOnly)
        }
    }

    fun onSortFilter(sortBy: Sort) {
        _filterState.update {
            it.copy(sortBy = sortBy)
        }
    }

    fun onDestroy() {
        viewModelScope.cancel()
        mediaPlayerController.release()
    }

    fun play(radio: Radio) {
        if (uiState.value is UiState.AppReady) {
            val uisState = uiState.value as UiState.AppReady
            uiState.update {
                uisState.copy(currentRadio = radio)
            }
        }
        mediaPlayerController.play(radio)
    }

    fun onPlay() {
        mediaPlayerController.start()
    }

    fun onStop() {
        mediaPlayerController.stop()
    }

    fun changeVolume(volume: Float) {
        mediaPlayerController.changeVolume(volume)
    }

    fun onFavorite(targetRadio: Radio) {
        radios = radios.map {
            if (it == targetRadio) {
                it.copy(favorite = !it.favorite)
            } else {
                it
            }
        }.toMutableList()

        if(uiState.value is UiState.AppReady){
            val uisState = uiState.value as UiState.AppReady
            if(targetRadio  == uisState.currentRadio){
                val updatedRadio = targetRadio.copy(favorite = !targetRadio.favorite)
                uiState.update {
                    uisState.copy(currentRadio = updatedRadio)
                }
            }
        }
        filterContent()
    }

    fun onNext(){
        if(uiState.value is UiState.AppReady){
            val uisState = uiState.value as UiState.AppReady
            val index = radios.indexOf(uisState.currentRadio)
            val toPlay = if(index+1 <= radios.size -1){
                radios[index + 1]
            }else{
                radios.first()
            }
            onStop()
            play(toPlay)
        }else{
            play(radios.first())
        }
    }
    fun onPrevious(){
        if(uiState.value is UiState.AppReady){
            val uisState = uiState.value as UiState.AppReady
            val index = radios.indexOf(uisState.currentRadio)
            val toPlay = if(index -1 >= 0){
                radios[index - 1]
            }else{
                radios.last()
            }
            onStop()
            play(toPlay)
        }else{
            play(radios.last())
        }
    }

}
