package com.radioshqiplive.components.icons

import androidx.compose.ui.graphics.vector.ImageVector
import com.radioshqiplive.components.icons.ekicon.AlbanianEagle1
import com.radioshqiplive.components.icons.ekicon.AlbanianEagle10
import com.radioshqiplive.components.icons.ekicon.AlbanianEagle2
import com.radioshqiplive.components.icons.ekicon.AlbanianEagle3
import com.radioshqiplive.components.icons.ekicon.AlbanianEagle4
import com.radioshqiplive.components.icons.ekicon.AlbanianEagle5
import com.radioshqiplive.components.icons.ekicon.AlbanianEagle6
import com.radioshqiplive.components.icons.ekicon.AlbanianEagle7
import com.radioshqiplive.components.icons.ekicon.AlbanianEagle8
import com.radioshqiplive.components.icons.ekicon.AlbanianEagle9
import com.radioshqiplive.components.icons.ekicon.ArrowLeft
import com.radioshqiplive.components.icons.ekicon.ArrowRight
import com.radioshqiplive.components.icons.ekicon.Calendar
import com.radioshqiplive.components.icons.ekicon.DesktopMode
import com.radioshqiplive.components.icons.ekicon.Download
import com.radioshqiplive.components.icons.ekicon.DownloadApple
import com.radioshqiplive.components.icons.ekicon.DownloadAppleMono
import com.radioshqiplive.components.icons.ekicon.DownloadAppstore
import com.radioshqiplive.components.icons.ekicon.DownloadAppstoreMono
import com.radioshqiplive.components.icons.ekicon.DownloadLinux
import com.radioshqiplive.components.icons.ekicon.DownloadLinuxMono
import com.radioshqiplive.components.icons.ekicon.DownloadMicrosoft
import com.radioshqiplive.components.icons.ekicon.DownloadMicrosoftMono
import com.radioshqiplive.components.icons.ekicon.FlagAl
import com.radioshqiplive.components.icons.ekicon.FlagIt
import com.radioshqiplive.components.icons.ekicon.FlagUs
import com.radioshqiplive.components.icons.ekicon.Flagalsmall
import com.radioshqiplive.components.icons.ekicon.Flagitsmall
import com.radioshqiplive.components.icons.ekicon.Flagussmall
import com.radioshqiplive.components.icons.ekicon.Github
import com.radioshqiplive.components.icons.ekicon.GithubMono
import com.radioshqiplive.components.icons.ekicon.Home
import com.radioshqiplive.components.icons.ekicon.Linkedin
import com.radioshqiplive.components.icons.ekicon.LinkedinMono
import com.radioshqiplive.components.icons.ekicon.LogoComplex
import com.radioshqiplive.components.icons.ekicon.LogoLarge
import com.radioshqiplive.components.icons.ekicon.Message
import com.radioshqiplive.components.icons.ekicon.MobileMode
import com.radioshqiplive.components.icons.ekicon.Moon
import com.radioshqiplive.components.icons.ekicon.NavAbout
import com.radioshqiplive.components.icons.ekicon.NavContact
import com.radioshqiplive.components.icons.ekicon.NavHome
import com.radioshqiplive.components.icons.ekicon.NavPortfoglio
import com.radioshqiplive.components.icons.ekicon.NavResume
import com.radioshqiplive.components.icons.ekicon.Playstore
import com.radioshqiplive.components.icons.ekicon.PlaystoreMono
import com.radioshqiplive.components.icons.ekicon.Sent
import com.radioshqiplive.components.icons.ekicon.Sun
import com.radioshqiplive.components.icons.ekicon.User
import kotlin.collections.List as ____KtList

public object EKIcon

private var __AllIcons: ____KtList<ImageVector>? = null

public val EKIcon.AllIcons: ____KtList<ImageVector>
  get() {
    if (__AllIcons != null) {
      return __AllIcons!!
    }
    __AllIcons= listOf(DownloadLinux, NavPortfoglio, Flagitsmall, FlagIt, User, DesktopMode, Home,
        Github, AlbanianEagle10, GithubMono, AlbanianEagle8, AlbanianEagle9, DownloadAppstoreMono,
        ArrowLeft, NavAbout, Download, PlaystoreMono, DownloadLinuxMono, LogoComplex,
        DownloadMicrosoftMono, ArrowRight, MobileMode, FlagAl, DownloadAppleMono, DownloadAppstore,
        FlagUs, Message, Flagalsmall, NavHome, Sun, Playstore, Sent, LinkedinMono,
        DownloadMicrosoft, AlbanianEagle4, AlbanianEagle5, Linkedin, AlbanianEagle7, Flagussmall,
        NavResume, Calendar, NavContact, AlbanianEagle6, AlbanianEagle2, AlbanianEagle3,
        DownloadApple, AlbanianEagle1, Moon, LogoLarge)
    return __AllIcons!!
  }
