import androidx.compose.animation.AnimatedVisibility
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Circle
import androidx.compose.material.icons.filled.Favorite
import androidx.compose.material.icons.filled.FavoriteBorder
import androidx.compose.material.icons.filled.PlayCircle
import androidx.compose.material.icons.filled.SkipNext
import androidx.compose.material.icons.filled.SkipPrevious
import androidx.compose.material.icons.filled.StopCircle
import androidx.compose.material.icons.filled.VolumeDown
import androidx.compose.material.icons.filled.VolumeUp
import androidx.compose.material3.Card
import androidx.compose.material3.CardElevation
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.Slider
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.alpha
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.RectangleShape
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.unit.dp
import com.radioshqiplive.AudioVisualizr
import com.radioshqiplive.components.icons.EKIcon
import com.radioshqiplive.components.icons.ekicon.DownloadApple
import com.radioshqiplive.data.UiState
import com.radioshqiplive.data.model.Radio
import com.radioshqiplive.player.PlayerState
import com.radioshqiplive.player.PlayerUiState
import com.seiko.imageloader.rememberAsyncImagePainter


@Composable
internal fun RadioPlayerUi(
    modifier: Modifier,
    onPlay: () -> Unit,
    onStop: () -> Unit,
    onNext: () -> Unit,
    onPrevious: () -> Unit,
    changeVolume: (Float) -> Unit,
    onFavorite: (Radio) -> Unit,
    playerState: PlayerUiState,
    currentRadio: Radio? = null,
) {
    var showVolume by remember { mutableStateOf(false) }

        Column(
            modifier = modifier.padding(start = 8.dp, end = 8.dp, top = 4.dp, bottom = 4.dp),
        ) {
            Row {

                Card {
                    Image(
                        modifier = Modifier.height(68.dp).aspectRatio(1.4f),
                        painter = rememberAsyncImagePainter(playerState.logoUrl),
                        contentDescription = null
                    )
                }
                Spacer(modifier = Modifier.size(8.dp))
                Column {
                    Text(
                        text = playerState.title,
                        maxLines = 1
                    )
                    Text(
                        text = playerState.subtitle,
                        maxLines = 2
                    )
                }
            }


//            Box() {

//                AudioVisualizr(
//                    isAnimating = playerState.playerState == PlayerState.Playing,
//                    modifier = Modifier.fillMaxWidth().height(46.dp).alpha(0.4f),
//                )

                Row(
                    modifier = Modifier.fillMaxWidth(),
                    verticalAlignment = Alignment.CenterVertically
                ) {

                    Spacer(
                        modifier = Modifier.weight(0.5f)
                    )

                    IconButton(
                        modifier = Modifier.size(32.dp),
                        onClick = { showVolume = !showVolume }
                    ) {
                        Icon(
                            imageVector = Icons.Default.VolumeUp,
                            contentDescription = "Volume"
                        )
                    }
                    Spacer(
                        modifier = Modifier.size(24.dp)
                    )
                    IconButton(
                        modifier = Modifier.size(32.dp),
                        onClick = onPrevious
                    ) {
                        Icon(
                            imageVector = Icons.Default.SkipPrevious,
                            contentDescription = "Previous"
                        )
                    }
                    Spacer(
                        modifier = Modifier.size(24.dp)
                    )

                    when (playerState.playerState) {
                        PlayerState.Loading -> {
                            CircularProgressIndicator(modifier = Modifier.size(24.dp))
                        }

                        PlayerState.Playing -> {
                            IconButton(
                                modifier = Modifier.size(46.dp),
                                onClick = onStop
                            ) {
                                Icon(
                                    imageVector = Icons.Default.StopCircle,
                                    contentDescription = "Stop"
                                )
                            }
                        }

                        PlayerState.Stoped -> {
                            IconButton(
                                modifier = Modifier.size(46.dp),
                                onClick = onPlay
                            ) {
                                Icon(
                                    imageVector = Icons.Default.PlayCircle,
                                    contentDescription = "Play"
                                )
                            }
                        }

                        PlayerState.Idle -> {
                            IconButton(
                                modifier = Modifier.size(46.dp),
                                onClick = {}
                            ) {
                                Icon(
                                    imageVector = Icons.Default.Circle,
                                    contentDescription = "Nothing"
                                )
                            }
                        }
                    }

                    Spacer(
                        modifier = Modifier.size(24.dp)
                    )
                    IconButton(
                        modifier = Modifier.size(32.dp),
                        onClick = onNext
                    ) {
                        Icon(
                            imageVector = Icons.Default.SkipNext,
                            contentDescription = "Next"
                        )
                    }

                    Spacer(
                        modifier = Modifier.size(24.dp)
                    )
                    IconButton(
                        modifier = Modifier.size(32.dp),
                        onClick = {
                            if(currentRadio!=null){
                                onFavorite(currentRadio)
                            }
                        }
                    ) {
                        if(currentRadio != null){
                            Icon(
                                imageVector = if (currentRadio.favorite) Icons.Default.Favorite else Icons.Default.FavoriteBorder,
                                contentDescription = if (currentRadio.favorite) "unfavorite" else "favorite",
                                tint = if (currentRadio.favorite) Color.Red else Color.Gray
                            )
                        }else{
                            Icon(
                                imageVector = Icons.Default.FavoriteBorder,
                                contentDescription = null,
                                tint = Color.Gray
                            )
                        }


                    }

                    Spacer(
                        modifier = Modifier.weight(0.5f)
                    )

                }

//            }


            Row(
                modifier = Modifier.fillMaxWidth()
                    .padding( top = 2.dp, bottom = 2.dp),
                verticalAlignment = Alignment.CenterVertically
            ) {


                Spacer(
                    modifier = Modifier.size(8.dp)
                )
                Column {
                    Text(
                        text = "Degjimi:",
                        maxLines = 1
                    )
                    Text(
                        text = playerState.time.toReadable(),
                        maxLines = 1
                    )
                }
                Spacer(
                    modifier = Modifier.size(8.dp)
                )

                AudioVisualizr(
                    isAnimating = playerState.playerState == PlayerState.Playing,
                    modifier = Modifier.weight(1f).height(30.dp).alpha(0.4f),
                )

                Spacer(
                    modifier = Modifier.size(8.dp)
                )
                Column {
                    Text(
                        text = "Timer:",
                        maxLines = 1
                    )
                    Text(
                        text = "00:00",
                        maxLines = 1
                    )
                }

            }

            AnimatedVisibility(showVolume) {
                Row {


                    IconButton(
                        modifier = Modifier.size(46.dp),
                        onClick = { changeVolume(0f) }
                    ) {
                        Icon(
                            imageVector = Icons.Default.VolumeDown,
                            contentDescription = "Mute"
                        )
                    }

                    Slider(
                        modifier = Modifier.weight(1f),
                        value = playerState.volume,
                        onValueChange = changeVolume,
                        steps = 100
                    )

                    IconButton(
                        modifier = Modifier.size(46.dp),
                        onClick = { changeVolume(1f) }
                    ) {
                        Icon(
                            imageVector = Icons.Default.VolumeUp,
                            contentDescription = "Up"
                        )
                    }

                }
            }
        }

}

fun Long.toReadable(): String {
    val minutes = (this / 1000) / 60
    val seconds = (this / 1000) % 60
//    return "%02d:%02d".format(minutes, seconds)
//    return String.format("%02d:%02d", minutes, seconds)
    return  "$minutes:$seconds"
}