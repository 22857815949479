package com.radioshqiplive.data

import androidx.compose.runtime.compositionLocalOf


const val APP_TITLE = "RadioShqip - Albanian Radios"
const val APP_WIDTH = 420
const val APP_HEIGHT = 720


val LocalContent = compositionLocalOf { englishContent }

//settings
data class SettingsContent(
    val title: String,
    val version: String
)

val settingsEnglish = SettingsContent(
    title = "Settings",
    version = "1.0"
)

val settingsAlbanian = SettingsContent(
    title = "Preferencat",
    version = "1.0"
)

//app content
data class AppContent(
    val title: String,
    val radios: String,
    val favorites : String
)

val appContentEnglish = AppContent(
    title = "RadioShqip",
    radios = "Radios",
    favorites = "Favorites"
)

val appContentAlbanian = AppContent(
    title = "RadioShqip",
    radios = "Radiot",
    favorites = "Preferuarat"
)

//about
data class AboutContent(
    val title: String,
    val body: String,
)

val aboutEnglish = AboutContent(
    title = "About",
    body = "Albanian Radio streaming app"
)

val aboutAlbanian = AboutContent(
    title = "Informacion",
    body = "Radio shqip live"
)

//app content
data class EkContent(
    val app: AppContent,
    val about: AboutContent,
    val settings: SettingsContent
)

val englishContent = EkContent(
    app = appContentEnglish,
    about = aboutEnglish,
    settings = settingsEnglish
)

val albanianContent = EkContent(
    app = appContentAlbanian,
    about = aboutAlbanian,
    settings = settingsAlbanian
)
