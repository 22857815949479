package com.radioshqiplive.data.service

import com.radioshqiplive.data.model.Radio
import io.ktor.client.HttpClient
import io.ktor.client.call.body
import io.ktor.client.plugins.HttpTimeout
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.client.request.get
import io.ktor.serialization.kotlinx.json.json
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.json.Json

interface RadioApi {
    suspend fun getKanalet(): List<Radio>
}

class RadioApiImpl : RadioApi {

    override suspend fun getKanalet(): List<Radio> {
        val response = client.get(SERVER_URL).body<String>()
        val radioListType = ListSerializer(Radio.serializer())
        return  Json { ignoreUnknownKeys = true }.decodeFromString(radioListType, response)
    }

    private val client = HttpClient {
        expectSuccess = true
        install(HttpTimeout) {
            val timeout = 30000L
            connectTimeoutMillis = timeout
            requestTimeoutMillis = timeout
            socketTimeoutMillis = timeout
        }
        install(ContentNegotiation) {
            json(Json { isLenient = true; ignoreUnknownKeys = true })
        }
    }

    companion object{
        const val SERVER_URL = "https://raw.githubusercontent.com/eltonkola/mediashqip/master/radio/list.json"
    }
}
