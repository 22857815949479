package com.radioshqiplive.data.model

import kotlinx.serialization.Serializable

@Serializable
data class Radio(
    val emri: String,
    val pershkrimi: String,
    val streamingUrl: String,
    val logo: String,
    val faqejaWeb: String,
    val webRadio: Boolean,
    val publikuar: Boolean,
    val vendi: String,
    val scrapeUrl: String,
    val zgjedhur: Boolean,
    val orderz: Int,
    val favorite: Boolean = false
)
