package com.radioshqiplive.model

data class AppState(
    var webMode: Boolean,
    var darkMode: Boolean = false,
    var language: Lang = Lang.English,
    var page: Page = Page.App
)

sealed class Lang {
    object Albanian : Lang()
    object English : Lang()
}

sealed class Page {
    object App : Page()
    object Settings : Page()
    object About : Page()
}
