package com.radioshqiplive.player

import com.radioshqiplive.data.model.Radio
import kotlinx.coroutines.flow.StateFlow


sealed class PlayerState {
    object Idle : PlayerState()
    object Loading : PlayerState()
    object Playing : PlayerState()
    object Stoped : PlayerState()
}

data class PlayerUiState (
    val title: String = "Radio Shqip",
    val subtitle: String = "Degjo Shqip!!",
    val logoUrl: String = "https://raw.githubusercontent.com/eltonkola/mediashqip/master/radio/logo/lepurushja.png",
    val time: Long = 0,
    val volume: Float = 1f,
    val playerState: PlayerState = PlayerState.Idle
)

expect class MediaPlayerController {

    val state : StateFlow<PlayerUiState>
    fun play(radio: Radio)
    fun start()
    fun stop()
    fun release()
    fun changeVolume(volume: Float)
    fun setMediaList(radios: List<Radio>)
}
