package com.radioshqiplive.player

import com.radioshqiplive.data.model.Radio
import kotlinx.browser.document
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import org.w3c.dom.HTMLAudioElement

actual class MediaPlayerController {


    private val _state = MutableStateFlow(PlayerUiState())
    actual val state : StateFlow<PlayerUiState> = _state

    private val audioElement = document.createElement("audio") as HTMLAudioElement

    actual fun play(radio: Radio){
        _state.value = _state.value.copy(
            title = radio.emri,
            subtitle = radio.pershkrimi,
            logoUrl = radio.logo,
            playerState = PlayerState.Loading
        )

        audioElement.src = radio.streamingUrl
        audioElement.addEventListener("canplaythrough", {
            // Audio is ready to play without interruption
            audioElement.play()
            updateState(PlayerState.Playing)
        })

        audioElement.onended = {
            updateState(PlayerState.Stoped)
        }
        audioElement.addEventListener("error", {
            updateState(PlayerState.Stoped)
        })

        audioElement.onvolumechange = {
            updateVolume(audioElement.volume.toFloat())
        }

    }

    private fun updateState(state: PlayerState){
        _state.value = _state.value.copy(playerState = state)
    }

    private fun updateVolume(volume: Float){
        _state.value = _state.value.copy(volume = volume)
    }

    actual fun start(){
        audioElement.play()
    }

    actual fun stop(){
        audioElement.pause()
    }

    actual fun release(){

    }

    actual fun changeVolume(volume: Float){
        audioElement.volume = volume.toDouble()

    }

    actual fun setMediaList(radios: List<Radio>) {
    }

}