import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.aspectRatio
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Favorite
import androidx.compose.material.icons.filled.FavoriteBorder
import androidx.compose.material3.Card
import androidx.compose.material3.CardColors
import androidx.compose.material3.CardDefaults
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import com.radioshqiplive.components.icons.EKIcon
import com.radioshqiplive.components.icons.ekicon.DownloadApple
import com.radioshqiplive.data.model.Radio
import com.seiko.imageloader.rememberAsyncImagePainter
import kotlinx.coroutines.launch


@Composable
internal fun RadioList(
    modifier : Modifier,
    radios: List<Radio>,
    playRadio: (Radio) -> Unit,
    onFavorite: (Radio) -> Unit,
    currentRadio: Radio?,
) {
    val listState = rememberLazyListState()
    val coroutineScope = rememberCoroutineScope()
    LaunchedEffect(currentRadio){
        if(currentRadio!=null) {
            coroutineScope.launch {
                val index = radios.indexOf(currentRadio)
                if(index > 0 && (index < listState.firstVisibleItemIndex || index > listState.firstVisibleItemIndex + 6 )){

                    listState.animateScrollToItem(index = index)
                }
            }
        }
    }

    LazyColumn(
        state = listState,
        modifier = modifier.padding(8.dp),
        verticalArrangement = Arrangement.spacedBy(8.dp)
    ) {
        items(radios){ radio ->
            RadioRow(radio,  playRadio, onFavorite, radio == currentRadio)
        }
    }
}

@OptIn(ExperimentalMaterial3Api::class)
@Composable
internal fun RadioRow(
    radio: Radio,
    playRadio: (Radio) -> Unit,
    onFavorite: (Radio) -> Unit,
    isCurrent: Boolean
){
    Card(
        modifier = Modifier.fillMaxWidth(),
        onClick = { playRadio(radio) },
        colors = CardDefaults.cardColors(
            containerColor = if(isCurrent) Color.LightGray.copy(alpha = 0.4f) else Color.White.copy(alpha = 0.4f)
        )
    ) {
        Row(
            modifier = Modifier.fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Image(
                modifier = Modifier.height(80.dp).aspectRatio(1.4f),
                painter = rememberAsyncImagePainter(radio.logo),
                contentDescription = null
            )
            Column(
                modifier = Modifier.weight(1f)
            ) {
                Text(
                    text = radio.emri,
                    maxLines = 1,
                    fontSize = 14.sp
                )
                Text(
                    text = radio.pershkrimi,
                    maxLines = 2,
                    fontSize = 12.sp
                )
                Text(
                    text = radio.faqejaWeb,
                    maxLines = 1,
                    fontSize = 12.sp
                )
            }
            IconButton(onClick = {
                onFavorite(radio)
            }) {
                Icon(
                    imageVector = if (radio.favorite) Icons.Default.Favorite else Icons.Default.FavoriteBorder,
                    contentDescription = if (radio.favorite) "favorite" else "unfavorite",
                    tint = if (radio.favorite) Color.Red else Color.Gray
                )
            }
        }
    }
}