
import com.radioshqiplive.data.APP_TITLE
import org.jetbrains.skiko.wasm.onWasmReady

fun main() {
    onWasmReady {
        BrowserViewportWindow(APP_TITLE) {
            MainView()
        }
    }
}

