package com.radioshqiplive

import androidx.compose.animation.core.LinearEasing
import androidx.compose.animation.core.RepeatMode
import androidx.compose.animation.core.animateFloat
import androidx.compose.animation.core.animateFloatAsState
import androidx.compose.animation.core.infiniteRepeatable
import androidx.compose.animation.core.rememberInfiniteTransition
import androidx.compose.animation.core.tween
import androidx.compose.foundation.Canvas
import androidx.compose.runtime.Composable
import androidx.compose.runtime.State
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.StrokeCap
import androidx.compose.ui.unit.dp
import io.ktor.util.date.GMTDate
import kotlin.random.Random

const val MaxLinesCount = 100
@Composable
fun AudioVisualizr(
    isAnimating: Boolean = false,
    modifier : Modifier = Modifier
) {


    val heightDivider by animateFloatAsState(
        targetValue = if (isAnimating) 1f else 6f,
        animationSpec = tween(1000, easing = LinearEasing)
    )

    val infiniteAnimation = rememberInfiniteTransition()
    val animations = mutableListOf<State<Float>>()
    val random = remember { Random(GMTDate().timestamp) }

    val barWidthFloat = 10.dp.value
    val gapWidthFloat = 4.dp.value

    val barColor = Color.Red

    repeat(15) {
        val durationMillis = random.nextInt(500, 2000)
        animations += infiniteAnimation.animateFloat(
            initialValue = 0f,
            targetValue = 1f,
            animationSpec = infiniteRepeatable(
                animation = tween(durationMillis),
                repeatMode = RepeatMode.Reverse,
            )
        )
    }

    val initialMultipliers = remember {
        mutableListOf<Float>().apply {
            repeat(MaxLinesCount) { this += random.nextFloat() }
        }
    }

    Canvas(
        modifier = modifier,
    ) {

        val count = (this.size.width / (barWidthFloat + gapWidthFloat)).toInt().coerceAtMost(MaxLinesCount)

        val animatedVolumeWidth = count * (barWidthFloat + gapWidthFloat)
        var startOffset = (this.size.width - animatedVolumeWidth) / 2

        val barMinHeight = 0f
        val barMaxHeight = this.size.height / 2f / heightDivider

        repeat(count) { index ->
            val currentSize = animations[index % animations.size].value
            var barHeightPercent = initialMultipliers[index] + currentSize
            if (barHeightPercent > 1.0f) {
                val diff = barHeightPercent - 1.0f
                barHeightPercent = 1.0f - diff
            }
            val barHeight = math.lerp(barMinHeight, barMaxHeight, barHeightPercent)
            drawLine(
                color = barColor,
                start = Offset(startOffset,  this.center.y - barHeight / 2),
                end = Offset(startOffset, this.center.y + barHeight / 2),
                strokeWidth = barWidthFloat,
                cap = StrokeCap.Round,
            )
            startOffset += barWidthFloat + gapWidthFloat
        }

    }

}