package com.radioshqiplive.components

import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.background
import androidx.compose.foundation.border
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.offset
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.wrapContentSize
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.OutlinedButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import androidx.compose.ui.zIndex

/**
 * items : list of items to be render
 * defaultSelectedItemIndex : to highlight item by default (Optional)
 * useFixedWidth : set true if you want to set fix width to item (Optional)
 * itemWidth : Provide item width if useFixedWidth is set to true (Optional)
 * cornerRadius : To make control as rounded (Optional)
 * color : Set color to control (Optional)
 * onItemSelection : Get selected item index
 */
@Composable
fun SegmentedControl(
    items: List<String>,
    defaultSelectedItemIndex: Int = 0,
    onItemSelection: (selectedItemIndex: Int) -> Unit
) {
    val selectedIndex = remember { mutableStateOf(defaultSelectedItemIndex) }

    Row(
        modifier = Modifier
    ) {
        items.forEachIndexed { index, item ->
            val shape = when (index) {
                0 -> RoundedCornerShape(
                    topStartPercent = 8,
                    topEndPercent = 0,
                    bottomStartPercent = 8,
                    bottomEndPercent = 0
                )

                items.size - 1 -> RoundedCornerShape(
                    topStartPercent = 0,
                    topEndPercent = 8,
                    bottomStartPercent = 0,
                    bottomEndPercent = 8
                )

                else -> RoundedCornerShape(
                    topStartPercent = 0,
                    topEndPercent = 0,
                    bottomStartPercent = 0,
                    bottomEndPercent = 0
                )
            }
            Box(
                modifier = when (index) {
                    0 -> {
                        Modifier
                            .wrapContentSize()
                            .offset(0.dp, 0.dp)
                            .zIndex(if (selectedIndex.value == index) 1f else 0f)
                    }

                    else -> {
                        Modifier
                            .wrapContentSize()
                            .offset((-1 * index).dp, 0.dp)
                            .zIndex(if (selectedIndex.value == index) 1f else 0f)
                    }
                }
                    .clip(shape)
                    .border(
                        BorderStroke(
                            1.dp, if (selectedIndex.value == index) {
                                Color.Black
                            } else {
                                Color.Black.copy(alpha = 0.75f)
                            }
                        )
                    )
                    .clickable {
                        selectedIndex.value = index
                        onItemSelection(selectedIndex.value)
                    }.background(
                        if (selectedIndex.value == index) {
                            Color.Black
                        } else {
                            Color.Transparent
                        }
                    ).padding(start = 4.dp, end = 4.dp),

                ) {
                Text(
                    text = item,
                    fontWeight = FontWeight.Normal,
                    fontSize = 14.sp,
                    color = if (selectedIndex.value == index) {
                        Color.White
                    } else {
                        Color.Black.copy(alpha = 0.9f)
                    },
                )
            }
        }
    }
}