import androidx.compose.animation.ExperimentalAnimationApi
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.material3.CircularProgressIndicator
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Scaffold
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.dp
import com.radioshqiplive.ExpandableSearchView
import com.radioshqiplive.components.SegmentedControl
import com.radioshqiplive.data.RadioViewModel
import com.radioshqiplive.data.Sort
import com.radioshqiplive.data.UiState
import com.radioshqiplive.model.AppState
import com.radioshqiplive.vm.DemoViewVm


@OptIn(ExperimentalMaterial3Api::class
)
@Composable
internal fun MobileRootUi(appState: AppState, onUpdate: (AppState) -> Unit, vm : RadioViewModel) {

    val uiState by vm.uiState.collectAsState()
    val playerState by vm.playerState.collectAsState()
    val filterState by vm.filterState.collectAsState()
    Scaffold(
//        backgroundColor = Color.Transparent,
//        drawerBackgroundColor = EKTheme.colors().onPrimary.copy(alpha = 0.5f),
//        drawerScrimColor = DrawerDefaults.scrimColor,
        topBar =


        {


            ExpandableSearchView(
                searchDisplay = filterState.search,
                onSearchUpdate = vm::onSearchUpdate,
                onSettingsClick = {},
                modifier = Modifier
            )

//                topBar {
////                    scope.launch {
////                        showOptions = !showOptions
////                    }
//                }
        },
        bottomBar = {
//                bottomBar(uiState, onUpdate)
        },
    ) { innerPadding ->

        Box(
            modifier = Modifier.padding(innerPadding)
        ) {

            when(uiState){
                UiState.Loading -> {
                    CircularProgressIndicator(modifier = Modifier.size(46.dp))
                }
                is UiState.Error -> {
                    Text("Error loading radios!")
                }
                is UiState.AppReady -> {
                    val state = (uiState as UiState.AppReady)

                    Column(
                        modifier = Modifier.fillMaxSize()
                    ) {

                        RadioPlayerUi(
                            modifier = Modifier.fillMaxWidth(),
                            onPlay = vm::onPlay,
                            onStop = vm::onStop,
                            changeVolume = vm::changeVolume,
                            playerState = playerState,
                            onFavorite = vm::onFavorite,
                            currentRadio =  state.currentRadio,
                            onNext= vm::onNext,
                            onPrevious= vm::onPrevious
                        )
                        Row(
                            modifier = Modifier.padding(start = 8.dp, end = 8.dp, top = 2.dp, bottom = 2.dp)
                        ) {

                            val filters = listOf("All Radios", "Favorites")
                            SegmentedControl(
                                items = filters,
                                defaultSelectedItemIndex = if(filterState.favoritesOnly) 1 else 0
                            ) {
                                vm.onFavoriteFilter(it == 1)
                            }

                            Spacer(
                                modifier = Modifier.weight(1f)
                            )

                            val sort = listOf(Sort.Default, Sort.AZ, Sort.ZA, Sort.RANDOM )
                            SegmentedControl(
                                items = sort.map { it.name },
                                defaultSelectedItemIndex = filterState.sortBy.order
                            ) { selectedIndex ->
                                vm.onSortFilter( sort.first { it.order == selectedIndex })
                            }


                        }

                        RadioList(
                            modifier = Modifier.fillMaxWidth().weight(1f),
                            radios = state.radios,
                            playRadio = vm::play,
                            onFavorite = vm::onFavorite,
                            currentRadio =  state.currentRadio,
                        )
                    }
                }
            }
        }
    }
}
